<template>
  <section class="bg-green text-salmon inset">

    <div class="row-10 m-0">
      <div class="inset-2">
        <h2 class="interior-title md:text-center">
          {{ payload.title }}
        </h2>
      </div>
    </div>

    <div class="row-10">
      <div class="inset-2 col-8 md:col-3 lg:col-2">
        <div v-html="payload.text" />
      </div>
      <div class="interior-image-container md:col-3 lg:col-4 mt-12 md:mt-0 md:pl-12">
        <Image :src="payload.einzelbild" />
      </div>
    </div>

    <div class="row-10 fixOverflow mt-12">
      <div class="offset-1 col-8 md:offset-3 md:col-4 relative">
        <swiper
          v-if="payload.slider.length"
          :loop="true"
          :speed="750"
          :space-between="100"
          @swiper="onSwiper"
        >
          <swiper-slide v-for="slide in payload.slider" :key="slide.id">
            <div class="aspect a32 cover mb-4">
              <Image :src="slide.bild" />
            </div>
            <div class="caption" v-html="slide.bildtext" />
          </swiper-slide>
        </swiper>
        <div class="nextSlide" @click="swiper.slideNext()" />
        <div class="prevSlide" @click="swiper.slidePrev()" />
      </div>
    </div>

  </section>
</template>

<script>

import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { A11y } from 'swiper';

SwiperCore.use([A11y]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    payload: Object,
  },
  data() {
    return {
      swiper: {},
    };
  },
  methods: {
    onSwiper(swiper) { this.swiper = swiper; },
  },
};
</script>

<style lang="scss" scoped>

.interior-image-container {
  img {
    max-height: 60vh;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}
</style>
